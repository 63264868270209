
import styles from '../css/institucional.module.css';


export const Calendario = () => {
  return (
    <>
    <div className="col-lg-12">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
            <h1>Seminario - 25A</h1>
            <hr class="red"/>
        </div>
        <div className="col-lg-2"></div>
    </div>
    
{/****** 14 Febrero*/}
<div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                14
            </div>
            <div className={styles.fecha_mes}>
                Feb
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Dr. Alfonso</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 21 Febrero*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                21
            </div>
            <div className={styles.fecha_mes}>
                Feb
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Rubén Gutiérrez Manso</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 28 Febrero*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                28
            </div>
            <div className={styles.fecha_mes}>
                Feb
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Lázaro Raudal González Leyva</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 07 Marzo*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                07
            </div>
            <div className={styles.fecha_mes}>
                Mar
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Irialys Calderón Morales</li>
                <li>José Abraham Téllez</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 14 Marzo*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                14
            </div>
            <div className={styles.fecha_mes}>
                Mar
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>David Pérez Martínez</li>
                <li>Alain Gómez Cabrera</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 21 Marzo*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                21
            </div>
            <div className={styles.fecha_mes}>
                Mar
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Mario Alberto Ibarra Carrio</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 28 Marzo*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                28
            </div>
            <div className={styles.fecha_mes}>
                Mar
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Jonathan Axel Cruz Vázquez</li>
                <li>Hernández Almendra Víctor Adonis</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 04 Abril*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                04
            </div>
            <div className={styles.fecha_mes}>
                Abr
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Jordi González</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 11 Abril*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                11
            </div>
            <div className={styles.fecha_mes}>
                Abr
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Abel Alejandro Rubín Alvarado</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 09 Mayo*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                09
            </div>
            <div className={styles.fecha_mes}>
                May
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>César Castrejón</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 23 Mayo*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                23
            </div>
            <div className={styles.fecha_mes}>
                May
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Saulo A. Gante Díaz</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 30 Mayo*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                30
            </div>
            <div className={styles.fecha_mes}>
                May
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Gustavo Mandujano</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 06 Junio*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                06
            </div>
            <div className={styles.fecha_mes}>
                Jun
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Carlos Murillo Aguilar</li>
                <li>Arturo Arvizu</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 13 Junio*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                13
            </div>
            <div className={styles.fecha_mes}>
                Jun
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Ariel Plasencia Díaz</li>
                <li>Joel Patricio José López</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 20 Junio*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                20
            </div>
            <div className={styles.fecha_mes}>
                Jun
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Jorge Saavedra</li>
                <li>Erik Sánchez</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 27 Junio*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                27
            </div>
            <div className={styles.fecha_mes}>
                Jun
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Édgar Muñoz Silva</li>
                <li>Sergio Delgadillo</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>

            {/****** Fin ******/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
            <h2>Presentaciones previas</h2>
            <hr class="red"/>
        </div>
        <div></div>
    </div>
    <div className="col-lg-12">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
            <h4>Seminario - 24B</h4>
            <hr class="red"/>
        </div>
        <div className="col-lg-2"></div>
    </div>
    {/****** 13 Septiembre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                13
            </div>
            <div className={styles.fecha_mes}>
                Sep
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Alberto Maldonado Romo</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 20 Septiembre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                20
            </div>
            <div className={styles.fecha_mes}>
                Sep
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Jordi González</li>
                <li>Jorge Alejandro Juárez Lora</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 27 Septiembre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                27
            </div>
            <div className={styles.fecha_mes}>
                Sep
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Elizabeth López Lozada</li>
                <li>Alejandro Hernández Herrera</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 4 Octubre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                4
            </div>
            <div className={styles.fecha_mes}>
                Oct
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Alain Cabrera</li>
                <li>José Abraham Téllez</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 11 Octubre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                11
            </div>
            <div className={styles.fecha_mes}>
                Oct
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>David Pérez Martínez</li>
                <li>Miguel Galicia</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 18 Octubre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                18
            </div>
            <div className={styles.fecha_mes}>
                Oct
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Mario Alfredo Ibarra Carrillo </li>
                <li>Irialys Calderón Morales</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 25 Octubre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                25
            </div>
            <div className={styles.fecha_mes}>
                Oct
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Angel Antonio Carreón Torres</li>
                <li>Joel Patricio José López</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 8 Noviembre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                8
            </div>
            <div className={styles.fecha_mes}>
                Nov
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li><a href="https://www.youtube.com/watch?v=KXHU7_O-ogg" target="new"><span class="glyphicon glyphicon-facetime-video" aria-hidden="true"></span></a> Lázaro Raudal Gonzalez Leyva</li>
                <li><a href="https://www.youtube.com/watch?v=3B6IIQZHbiI" target="new"><span class="glyphicon glyphicon-facetime-video" aria-hidden="true"></span></a> Erick Gil de Paz</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 15 Noviembre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                15
            </div>
            <div className={styles.fecha_mes}>
                Nov
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li><a href="https://www.youtube.com/watch?v=NVedYwCsujc" target="new"><span class="glyphicon glyphicon-facetime-video" aria-hidden="true"></span></a> Saulo A. Gante Díaz</li>
                <li><a href="https://www.youtube.com/watch?v=_Tu1obxpe4s" target="new"><span class="glyphicon glyphicon-facetime-video" aria-hidden="true"></span></a> Rubén Gutiérrez Manso</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 29 Noviembre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                29
            </div>
            <div className={styles.fecha_mes}>
                Nov
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li><a href="https://www.youtube.com/watch?v=CnPwDte7dug" target="new"><span class="glyphicon glyphicon-facetime-video" aria-hidden="true"></span></a> Jonathan Axel Cruz Vázquez</li>
                <li><a href="https://www.youtube.com/watch?v=QyBIcQfiIhI" target="new"><span class="glyphicon glyphicon-facetime-video" aria-hidden="true"></span></a> Carlos Murillo Aguilar</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
    {/****** 6 diciembre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                6
            </div>
            <div className={styles.fecha_mes}>
                Dic
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                {/*<li>Liliabet Vega Ledesma</li>*/}
                <li><a href="https://www.youtube.com/watch?v=jLPwVMOyzUo" target="new"><span class="glyphicon glyphicon-facetime-video" aria-hidden="true"></span></a> Ariel Plasencia Díaz</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>
 
    {/****** 13 diciembre*/}
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                13
            </div>
            <div className={styles.fecha_mes}>
                Dic
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>César Castrejón</li>
                <li>Arturo Arvizu</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>

    {/****** 22 Noviembre
    <div className="col-lg-12">
        &nbsp;
    </div>
    <div className="col-lg-12">
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-1">
            <div className={styles.fecha_dia}>
                22
            </div>
            <div className={styles.fecha_mes}>
                Nov
            </div>
        </div>
        <div className="col-lg-1">&nbsp;</div>
        <div className="col-lg-8">
            <ul>
                <li>Víctor Adonis Hernández Almendra</li>
                <li>Jorge Saavedra</li>
            </ul>
        </div>
        <div className="col-lg-1">&nbsp;</div>
    </div>*/}
    </>
  );
}