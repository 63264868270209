import React, { useEffect, useRef, useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


/* Banners anunciando lo más importante por el momento*/ 
import cursoDrones1 from "../images/Banner_Images/CursoDrones1.png";
import cursoDrones2 from "../images/Banner_Images/CursoDrones2.png";
import RIPAIS_Kickoff from "../images/Banner_Images/RIPAIS_Kickoff.png";
import artCaderaDeEva from "../images/Banner_Images/art_CaderaDeEva.png";
import articuloEmoRecognition from "../images/Banner_Images/art_emotion_recognition.png";
import articuloDataset from "../images/Banner_Images/art_Dataset_JATIT.png";
import articuloReconEmo from "../images/Banner_Images/OnceNoticias_AnalisisEmociones.png";
import semCafeCompMat_20_02 from "../images/Banner_Images/Seminario_20_02_2025.png";
import cursoCiberseguridad from "../images/Banner_Images/Cisco_Curso.png";

/****Banners relacionados a las redes sociales***********************/
/*****cambian diariamente utilizando la variable 'visible' *****/
import bannerYT from "../images/Banner_Images/VisitYouTube.png";
import bannerSocial from "../images/Banner_Images/VisitSocials.png";
import bannerMEXCIR from "../images/Banner_Images/MEXCIR_2025.png";

import bannerCIC from "../images/Banner_Images/VisitCIC.png";

import bannerConversus from "../images/Banner_Images/RevistaConversus.png";

/****Banners relacionados a otros medios que publican acerca del laboratorio***********************/
/*****cambian diariamente utilizando la variable 'visible2' *****/
import reconocimientoCC from "../images/Banner_Images/Reconocimiento_CC.png";
import articuloCIC from "../images/Banner_Images/Publicacion_dic_2024_1.png";
import articulo2024Medico from "../images/Banner_Images/articulo2024Medico.png";

import inicioSemestre from "../images/Banner_Images/Inicio_Semestre.png";


import bannerRIPAISC from "../images/Banner_Images/RIPAISC.png";


import bannerEIR1 from "../images/Banner_Images/Final_EIR_1.png";




export const Banner_Lab = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const visible2 = (new Date().getDay()) % 2;
    const visible3 = (new Date().getDay()) % 3;
    const visible4 = (new Date().getDay()) % 4;

    const mes = (new Date().getMonth());

    useEffect(() => {

        const slides = document.getElementsByClassName("myBanner");
        for (let i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
        }
    
        const showSlides = () => {
          const slides = document.getElementsByClassName("myBanner");
          for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
          }
          setSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
          slides[slideIndex].style.display = "block";
        };
    
    {/*}
    const showSlides = () => {
        const slides = document.getElementsByClassName("myBanner");
        const i=0;
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";  
          }
          //slideIndex++;
          if (slideIndex > slides.length) {slideIndex = 1}
          slides[slideIndex-1].style.display = "block";
          //setTimeout(showSlides2, 2000); // Change image every 2 seconds
      }
        */}
        const interval = setInterval(showSlides, 7000); // Cambiar la imagen cada 3 segundos (2000 milisegundos)
    
        return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
    
      }, [slideIndex]);
    
      useEffect(() => {
        const slides = document.getElementsByClassName("myBanner");
        for (let i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
        }
        slides[slideIndex].style.display = "block";
      }, [slideIndex]);
 


  return (
    <>
      <div>
        <div>
            <div>
                <div  className='myBanner'>&nbsp;</div>
                <div  className='myBanner'>&nbsp;</div>
                {/********Sólo se activa del 10 al 19 de Febrero***********
                <div className={visible2 === 1 ? 'myBanner' : 'hidden'}>
                    <img src={inicioSemestre} width="100%" alt='Banner inicio de semestre 2025-A'/>
                </div>*/}
              
                {/********Banners Regulares***********
                <div className='myBanner'>
                    <a href='https://femexrobotica.org/eir2024-2025/' target='new'><img src={bannerEIR1} width="100%" alt='Banner de agradecimiento de participación en Escuela de Invierno de Robótica 2024'/></a>
                </div>
                
                <div className='myBanner'>
                    <a href='https://www.ripaisc.net/' target='new'><img src={bannerRIPAISC} width="100%" alt='Banner de Red Iberoamericana de Investigación Cuántica'/></a>
                </div>
                
                <div className='myBanner'>
                    <a href='https://drive.google.com/file/d/16KK9GsB_Lp_0-uG1qcE9H82sKpHZ0ccN/view?fbclid=IwY2xjawF1lzRleHRuA2FlbQIxMQABHancPQc5MS5_n4ATEorgd9Gpf48Ey_PDJCKPX9MsTa6EV5NewoTnXNV4tQ_aem_gt4hoc6tcrkUGF4AqFaZgQ&sfnsn=scwspmo' target='new'><img src={bannerConversus} width="100%" alt='Banner de revista Conversus con temas de Computación Cuántica'/></a>
                </div>
              
                <div className='myBanner'>
                    <a href='https://www.cic.ipn.mx/' target='new'><img src={bannerCIC} width="100%" alt='Banner de promoción del CIC'/></a>
                </div>
                */}
                {/**********Banners relacionados a Redes Sociales 
                <div className={visible === 1 ? 'myBanner' : 'hidden'}>
                    <a href= target='new'><img src={bannerYT} width="100%" alt='Banner de video en YouTube'/></a>
                </div>
                <div className={visible === 2 ? 'myBanner' : 'hidden'}>
                  <img src={bannerSocial} width="100%" alt='Banner con la descripción de todas las redes sociales'/>
                </div>
                <div className={visible === 0 ? 'myBanner' : 'hidden'}>
                  <a href="https://femexrobotica.org/mexcir2025/" target='new'><img src={bannerMEXCIR} width="100%" alt='Banner con invitación a MEXCIR2025'/></a>
                </div>
                */}

                {/********Banners relacionados a Artículos 
                <div className={visible === 0 ? 'myBanner' : 'hidden'}>
                <a href="https://ipn.mx/gacetas/seleccion-gaceta.html?fbclid=IwY2xjawG63UxleHRuA2FlbQIxMAABHWicpnqTnmvsjIOosEfJV02IWSjTGTvASlkD5xfxrL-KHZPWcevyg2m-KA_aem_sNjHNsQDgHwaVgW2w0xeRw" target="new"><img src={reconocimientoCC} width="100%" alt='Banner de Reconocimiento a Alumno'/></a>
                </div>
                <div className={visible === 2 ? 'myBanner' : 'hidden'}>
                <a href='https://ipn.mx/gacetas/seleccion-gaceta.html' target='new'><img src={articulo2024Medico} width="100%" alt='Banner con artículo relacionado al Laboratorio de Robótica y Mecatrónica'/></a>
                </div>
                <div className={visible === 1 ? 'myBanner' : 'hidden'}>
                <a href='https://oncenoticias.digital/agenda-ipn/robots-con-ia-asumiran-labores-riesgosas-investigador-del-ipn/421590/amp/?fbclid=IwY2xjawHNYllleHRuA2FlbQIxMQABHcXPPvEmDr36FeBKjcRXYSWyBJ3Jv_ZENJqIyMEhEnvAkyJokkBIpIdsKA_aem_OlgN3N6OQ0z3GyoukCiz1g' target='new'><img src={articuloCIC} width="100%" alt='Banner con relación a artículo de Laboratorio de Robótica y Mecatrónica'/></a>
                </div>
                    */}
                {/********Sólo se activa en Enero***********
                <div className={mes === 0 ? 'myBanner' : 'hidden'}>
                    <img src={fiestas3} width="100%" alt='Banner de Feliz Año Nuevo 2025'/>
                </div>
                */}
            </div>
        </div>
        <div>
        </div>
      </div>
    
      <div>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><img src={inicioSemestre} width="100%" alt='Banner de inicio de semestre 2025A'/></SwiperSlide>

        {/***Banner para anunciar el curso sabatino de Drones**********/}
        <SwiperSlide><a href='https://www.capacitacion.cic.ipn.mx/sysudep/Preinscripcion/Consulta_Sabatinos23.php' target='new'>
            <img src={visible2 === 0 ? cursoDrones1 : cursoDrones2} width="100%" alt='Banner Curso Sabatino de Drones'/></a>
        </SwiperSlide>
        
        <SwiperSlide><a href='https://docs.google.com/forms/d/e/1FAIpQLScs7rXva6EnpeYcjxNNxKc43T4C9GZI6s7OkTJfZa5tW0FC5Q/viewform?pli=1' target='_new'><img src={cursoCiberseguridad} width="100%" alt='Banner de Curso de CISCO de Ciberseguridad'/></a></SwiperSlide>

        <SwiperSlide><a href='https://lacaderadeeva.com/entrevistas/3-cientificas-del-politecnico-que-debes-conocer/12630' target='_new'><img src={artCaderaDeEva} width="100%" alt='Banner de Artículo de Proyecto de Movilidad por Dra. Elsa Rubio'/></a></SwiperSlide>

        <SwiperSlide><a href='https://www.facebook.com/OnceNoticiasTV/videos/644493334805920' target='new'><img src={articuloReconEmo} width="100%" alt='Banner de entrevista acerca de Reconocimiento de Emociones'/></a></SwiperSlide>
        
        <SwiperSlide><a href='https://www.youtube.com/watch?v=AFvMbgX7a4s' target='_new'><img src={RIPAIS_Kickoff} width="100%" alt='Banner de agradecimiento de participación en Escuela de Invierno de Robótica 2024'/></a></SwiperSlide>

        {/****Banner para artículos publicados*/}
        <SwiperSlide>
          <a href={visible2 === 0 ? 'https://www.mdpi.com/2227-7390/13/2/254' : 'https://www.jatit.org/volumes/Vol103No2/18Vol103No2.pdf'} target='new'>
          <img src={visible2 === 0 ? articuloEmoRecognition : articuloDataset} width="100%" alt='Banner de artículo de Reconocimiento de Emociones'/>
          </a>
        </SwiperSlide>
        
        
        <SwiperSlide><a href='https://proyectosrym.cic.ipn.mx/#/EIR2024' target='_self'><img src={bannerEIR1} width="100%" alt='Banner de agradecimiento de participación en Escuela de Invierno de Robótica 2024'/></a></SwiperSlide>
        <SwiperSlide><a href='https://www.cic.ipn.mx/index.php/eventoscic/ei-2025/973-01-03-25-a-07-10-25-seminario-de-matematicas-computacion-y-cafe' target='new'><img src={semCafeCompMat_20_02} width="100%" alt='Banner de invitación a Seminario café, computación y matemáticas'/></a></SwiperSlide>
        <SwiperSlide><a href='https://www.ripaisc.net/' target='new'><img src={bannerRIPAISC} width="100%" alt='Banner de Red Iberoamericana de Investigación Cuántica'/></a></SwiperSlide>
        {/*<SwiperSlide><a href='https://drive.google.com/file/d/16KK9GsB_Lp_0-uG1qcE9H82sKpHZ0ccN/view?fbclid=IwY2xjawF1lzRleHRuA2FlbQIxMQABHancPQc5MS5_n4ATEorgd9Gpf48Ey_PDJCKPX9MsTa6EV5NewoTnXNV4tQ_aem_gt4hoc6tcrkUGF4AqFaZgQ&sfnsn=scwspmo' target='new'><img src={bannerConversus} width="100%" alt='Banner de revista Conversus con temas de Computación Cuántica'/></a></SwiperSlide>*/}
        
        {/****Banners relacionados a Redes Sociales********************/}
        <SwiperSlide>
            <a href={visible4 === 0 ? '' : visible4 === 1 ? 'https://femexrobotica.org/mexcir2025/' : visible4 === 2 ? 'https://www.youtube.com/watch?v=CKtgoL7uTqc&t=3s' : 'https://www.cic.ipn.mx/'}
               target={visible4 === 0 ? '_self' : '_new'}
            >
            <img src={visible4 === 0 ? bannerSocial : visible4 === 1 ? bannerMEXCIR : visible4 === 2 ? bannerYT : bannerCIC} 
                 width="100%" 
                 alt='Banners de todas las redes sociales'
            />
            </a>
        </SwiperSlide>
        {/***********Banner relacionados a Artículos publicados******************************* */}
        <SwiperSlide>
            <a href={visible4 === 0 ? 'https://ipn.mx/gacetas/seleccion-gaceta.html?fbclid=IwY2xjawG63UxleHRuA2FlbQIxMAABHWicpnqTnmvsjIOosEfJV02IWSjTGTvASlkD5xfxrL-KHZPWcevyg2m-KA_aem_sNjHNsQDgHwaVgW2w0xeRw' : visible4 === 1 ? 'https://ipn.mx/gacetas/seleccion-gaceta.html' : visible4 === 2 ? 'https://oncenoticias.digital/agenda-ipn/robots-con-ia-asumiran-labores-riesgosas-investigador-del-ipn/421590/amp/?fbclid=IwY2xjawHNYllleHRuA2FlbQIxMQABHcXPPvEmDr36FeBKjcRXYSWyBJ3Jv_ZENJqIyMEhEnvAkyJokkBIpIdsKA_aem_OlgN3N6OQ0z3GyoukCiz1g' : 'https://drive.google.com/file/d/16KK9GsB_Lp_0-uG1qcE9H82sKpHZ0ccN/view?fbclid=IwY2xjawF1lzRleHRuA2FlbQIxMQABHancPQc5MS5_n4ATEorgd9Gpf48Ey_PDJCKPX9MsTa6EV5NewoTnXNV4tQ_aem_gt4hoc6tcrkUGF4AqFaZgQ&sfnsn=scwspmo'}
               target='_new'
            >
            <img src={visible4 === 0 ? reconocimientoCC : visible4 === 1 ? articulo2024Medico : visible4 === 2 ? articuloCIC : bannerConversus} 
                 width="100%" 
                 alt='Banners de todas las redes sociales'
            />
            </a>
        </SwiperSlide>
        
      </Swiper>

      </div>
      
    </>
  );
};