
import foto from "../images/Equipo_de_trabajo/Erik.jpg";

export const ErikZ = () => {
  return (
    <>
    <h1 onLoad={window.scrollTo(0, 0)}>&nbsp;</h1>
    <div className="col-lg-1"></div>
    <div className="col-lg-10">
    <h3 id="inicio">Dr. Erik Zamora </h3>
          <hr class="red"/>
          <p align="right"><a href="https://www.ezamorag.com/" target="new">Página Personal</a></p>
          <div className="col-lg-3"><img src={foto} width="100%" alt="Foto Dr. Erik Zamora"></img></div>
          <div className="col-lg-9">
            <p><strong>Formación Académica</strong></p>
            <p><ul>
                <ol>Dr. en Informática</ol>
                <ol>M. en C. en Ingeniería Eléctrica</ol>
                <ol>Ing. en Comunicaciones y Eléctrica</ol>
              </ul></p>
              <p><strong>Unidades de Aprendizaje</strong></p>
              <p><ul>
                <ol>Programación Avanzada</ol>
                <ol>Redes Neuronales</ol>
                <ol>Reconocimiento de Patrones</ol>
              </ul></p>
              <p><strong>Líneas de investigación y desarrollo</strong></p>
              <p><ul>
                <li>Machine Learning </li>
                <li>Deep Learning</li>
              </ul></p>
            </div>
    </div>
    <div className="col-lg-1"></div>
    </>
  );
};