
import foto from "../images/Personal_Yalja/yalja_perfil.jpg"

import pi_mex from "../images/Personal_Yalja/02-mex-596x389.jpg";
import pi_gral from "../images/Personal_Yalja/general-3-596x461.png";
import pi_graf from "../images/Personal_Yalja/grafico-214x154.png";
import pi_img from "../images/Personal_Yalja/imagen-1-596x643.jpg";

import ind_sedena from "../images/Personal_Yalja/Ind_Sedena.jpeg";
import ind_sedena2 from "../images/Personal_Yalja/Ind_Sedena2.jpeg";
import ind_stcmetro from "../images/Personal_Yalja/Ind_STCMetro.jpeg";
import ind_stcmetro2 from "../images/Personal_Yalja/Ind_STCMetro2.png";

import tesis1 from "../images/Personal_Yalja/tesis_1.png";
import tesis2 from "../images/Personal_Yalja/tesis_2.png";
import tesis3 from "../images/Personal_Yalja/tesis_3.png";
import tesis4 from "../images/Personal_Yalja/tesis_4.png";

export const Yalja = () => {
  return (
    <>
       <h1 onLoad={window.scrollTo(0, 0)}>&nbsp;</h1>
       <div className="col-lg-1"></div>
       <div className="col-lg-10">
          <h3>Dr. Jesús Yaljá Montiel Pérez</h3>
          <hr class="red"/>
          <div className="col-lg-4"><img src={foto} width="100%" alt="Foto Dr. Yalja Montiel"></img></div>
          <div className="col-lg-8">
            <p><strong>Formación Académica</strong></p>
            <p><ul>
                <ol>Dr. en Comunicaciones y Electrónica</ol>
                <ol>M. en C. en Ingeniería electrónica</ol>
                <ol>Ing. Físico</ol>
              </ul></p>
              <p><strong>Unidades de Aprendizaje</strong></p>
              <p><ul>
                <ol>Modelado y control de robots</ol>
                <ol>Habilidades Industriales</ol>
                <ol>Análisis numérico</ol>
                <ol>Transformadas matemáticas para imágenes</ol>
                <ol>Mat. avanzadas, predicción y caos</ol>
                <ol>Percepción visual digital, CITEDI</ol>
                <ol>Series de tiempo</ol>
              </ul></p>
              <p><strong>Líneas de investigación y desarrollo</strong></p>
              <p><ul>
                <li>Computación cuántica aplicada</li>
                <li>Ciudades inteligentes</li>
                <li>Internet del cuerpo, IoB, sensores</li>
                <li>Internet de las cosas, IoT, sensores</li>
                <li>Robots de servicio</li>
                <li>Robots médicos</li>
                <li>Navegación</li>
                <li>Sensores y visión mejorada </li>
              </ul></p>
            </div>
            <h1>&nbsp;</h1>
            <h3>Proyectos de investigación</h3>
            <hr class="red"/>
            <div align="center">
              <div className="col-lg-3"><figure><img src={pi_mex} width = "80%" alt="Mexculture"/></figure><figcaption>Mexculture</figcaption></div>
              <div className="col-lg-3"><figure><img src={pi_gral} width = "80%" alt="Espectroscopia por fluorescencia"/></figure><figcaption>Espectroscopia por fluorescencia</figcaption></div>
              <div className="col-lg-3"><figure><img src={pi_graf} width = "80%" alt="Monitorización de signos vitales"/></figure><figcaption>Monitorización de signos vitales</figcaption></div>
              <div className="col-lg-3"><figure><img src={pi_img} width = "80%" alt="Iluminación inteligente"/></figure><figcaption>Iluminación inteligente, ciudades inteligentes</figcaption></div>
            </div>
            <div>&nbsp;</div>
            <div  className="col-lg-12">
            <p>
              <ul>
                <li>Herramienta para el entrenamiento del control de una línea del Metro, SECTEI-IPN</li>
                <li>Internet de las Cosas Resiliente, UCI-IPN</li>
                <li>Monitorización de signos vitales. SRE, SECTEI - IPN</li>
                <li>IA y espectroscopia por fluorescencia de tejido humano, CONACyT</li>
                <li>MEXCULTURE, Multimedia libraries indexing for preservation and disssemination of the mexican culture,</li>
                <li>CONACyT-ANR-Francia</li>
                <li>Proyectos SIP-IPN en las áreas de computación, robótica, imágenes, sensores, instrumentos,… </li>
              </ul>
            </p>
            </div>
            <h1>&nbsp;</h1>
            <h3>Proyectos vinculados con la industria</h3>
            <hr class="red"/>
            
            <div align="center"   className="col-lg-12">
              <div className="col-lg-3"><figure><img src={ind_sedena} width = "80%" alt="Proyecto SEDENA"/></figure><figcaption>SEDENA-FAM </figcaption></div>
              <div className="col-lg-3"><figure><img src={ind_sedena2} width = "80%" alt="Proyecto SEDENA"/></figure><figcaption>SEDENA-FAM </figcaption></div>
              <div className="col-lg-3"><figure><img src={ind_stcmetro} width = "80%" alt="proyecto STC Metro"/></figure><figcaption>STCMetro</figcaption></div>
              <div className="col-lg-3"><figure><img src={ind_stcmetro2} width = "80%" alt="Proyecto STC Metro"/></figure><figcaption>STCMetro-GCDMX</figcaption></div>
            </div>
            <div>&nbsp;</div>
            <div  className="col-lg-12">
            <p>
              <ul>
                <li>Soluciones de redes en campus ZTE 5G</li>
                <li>Plataforma de información y navegación aerotáctica, SEDENA-FAM-IPN</li>
                <li>Simulador de control de una línea del Metro, STC-GCDMX</li>
                <li>Sistema de tarifa cero para personas con discapacidad STCMetro </li>
              </ul>
            </p>
            </div>
            <h1>&nbsp;</h1>
            <h3>Tesis recientes y en desarrollo</h3>
            <hr class="red"/>
            <div align="center"   className="col-lg-12">
              <div className="col-lg-3"><figure><img src={tesis1} width = "80%" alt="Simulación de circuito cuántico con FPGA "/></figure><figcaption>Simulación de circuito cuántico con FPGA </figcaption></div>
              <div className="col-lg-3"><figure><img src={tesis2} width = "80%" alt="Teleoperación robot NAO "/></figure><figcaption>Teleoperación robot NAO </figcaption></div>
              <div className="col-lg-3"><figure><img src={tesis3} width = "80%" alt="Robot para extracción de sangre"/></figure><figcaption>Robot para extracción de sangre</figcaption></div>
              <div className="col-lg-3"><figure><img src={tesis4} width = "80%" alt="Simulación de propagación de ondas sísmicas"/></figure><figcaption>Simulación de propagación de ondas sísmicas</figcaption></div>
            </div>
            <div>&nbsp;</div>
            <div className="col-lg-12">
            <p>
              <ul>
                <li>Teleoperación de <strong>robots</strong></li>
                <li>Simulador <strong>robot</strong> extractor de sangre</li>
                <li>Navegación aleatoria de <strong>robots</strong> de servicio</li>
                <li>Iluminación <strong>inteligente</strong> para interiores</li>
                <li><strong>Identificador</strong> de modos de conducción</li>
                <li><strong>Detección</strong> de fatiga en conductores</li>
                <li><strong>Imágenes</strong> ruidosas y transformada coseno</li>
                <li><strong>Identificación</strong> de emociones con EEG</li>
                <li>Simulador de ondas de terremotos con <strong>FPGA</strong></li>
                <li>Bicóptero y control paralelo con <strong>FPGA</strong></li>
                <li>Arq. de procesador sistólico con <strong>FPGA</strong></li>
                <li>Simulador <strong>cuántico</strong> con <strong>FPGA</strong></li>
                <li>Clasificador <strong>cuántico</strong></li>
                <li>Predicción financiera <strong>cuántica</strong></li>
              </ul>
            </p>
            </div>
            <h1>&nbsp;</h1>
            <h3>Propuestas de tesis para maestría y doctorado</h3>
            <hr class="red"/>
            <p>
              <ol>
                <li>Parametrización inteligente de conducta de ratas expuestos a fármacos</li>
                <li>Análisis y predicción cuántica</li>
                <li>Control de enjambres de robots usando algoritmos cuánticos</li>
                <li>Estudios con orientación a Ciencias de la Tierra</li>
                <ul>
                  <li>Sensores sísmicos</li>
                  <li>Precursores sísmicos</li>
                  <li>Estudios de propagación de ondas sísmicas</li>
                </ul>
                <li>Visión mejorada como asistentes de conducción de automóvil</li>
                <li>Cómputo afectivo *</li>
                <ul>
                  <li>Robot emotivo</li>
                  <li>Servicio afectivo con EEG</li>
                  <li>Reconocimiento afectivo con EEG </li>
                </ul>
                <li>Robot de servicio médico</li>
                <ul>
                  <li>Garganta y nariz *</li>
                  <li>Sangre *</li>
                  <li>Suturas *</li>
                  <li>Limpieza por UVC</li>
                </ul>
                <li>IoT Resiliente, eficiencia y aplicaciones</li>
                <li>IoB, IoT para signos vitales y comportamientos humanos</li>
                <li>Diseño de enjambres de sensores para exploración de cavernas y ríos subterráneos</li>
                <li>Movilidad y conectividad 5G para robots</li>
                <li>Control de dispositivos usando FPGAs</li>
                <li>Cómputo paralelo con FPGAs</li>
                <li>Control de dispositivos usando FPGAs </li>
              </ol>
            </p>
       </div>
       
    </>
  );
};