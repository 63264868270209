
import foto from "../images/Personal_ElsaR/ElsaRubio.png";

export const ElsaR = () => {
  return (
    <>
    <h1 onLoad={window.scrollTo(0, 0)}>&nbsp;</h1>
    <div className="col-lg-1"></div>
    <div className="col-lg-10">
    <h3>Dra. Elsa Rubio Espino</h3>
    Jefa de Laboratorio
          <hr class="red"/>
          <div className="col-lg-3"><img src={foto} width="100%" alt="Foto Dra. Elsa Rubio"></img></div>
          <div className="col-lg-9">
            <p><strong>Formación Académica</strong></p>
            <p><ul>
                <ol>Dra. en Sistemas Automáticos</ol>
                <ol>M. en C. en Control Automático</ol>
                <ol>Ing. en Procesos Automáticos</ol>
              </ul></p>
              <p><strong>Unidades de Aprendizaje</strong></p>
              <p><ul>
                <ol>Matemáticas Discretas</ol>
                <ol>Modelado y Simulación </ol>
                <ol>Control Automático</ol>
                <ol>Álgebra Lineal Aplicada</ol>
                <ol>Recuperación y visualización de Información</ol>
              </ul></p>
              <p><strong>Líneas de investigación y desarrollo</strong></p>
              <p><ul>
                <li>Robots colaborativos</li>
                <li>Robótica de enjambre</li>
                <li>Movilidad Inteligente</li>
                <li>IoT para movilidad</li>
                <li>Sensores para lectura de datos</li>
              </ul></p>
            </div>
    </div>
    <div className="col-lg-1"></div>
    </>
  );
};